import { __rest } from "tslib";
import React, { forwardRef, useState, useEffect } from "react";
import InputMask from "../InputMask";
const InputPhone = forwardRef((_a, ref) => {
    var { disabled, defaultValue } = _a, props = __rest(_a, ["disabled", "defaultValue"]);
    const [value, setValue] = useState("");
    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue.toString());
        }
    }, []);
    return (React.createElement(InputMask, Object.assign({ ref: ref, mask: value.length <= 10 ? "(99) 9999-9999?" : "(99) 99999-9999", formatChars: {
            9: "[0-9]",
            "?": "[0-9 ]",
        }, maskChar: "", placeholder: "(71) 3333-3333" }, props, { value: value, onChange: (e) => {
            const phone = e.target.value.replace(/\D/g, "");
            setValue(phone);
        }, disabled: disabled })));
});
export default InputPhone;
